.brocherMain{
    position:fixed;
    width: 5rem;
    top: 88%;
    right: 1%;
    z-index: 100;
    overflow: hidden;
}

/* CSS */
.button-57 {
    border-radius: 100px;
  position: relative;
  overflow: hidden;
  height:4rem;
  border: 1px solid #d24545;
  color: #d24545;
  display: inline-block;
  font-size: 13px;
  line-height: 15px;
  /* padding: 18px 18px 11px; */
  text-decoration: none;
  cursor: pointer;
  background: transparent;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-weight: 800;
}

.button-57 span:first-child {
  position: relative;
  transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 10;
}

.button-57 span:last-child {
  color: white;
  display: block;
  position: absolute;
  bottom: 0;
  transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 100;
  opacity: 0;
  top: 52%;
  left: 50%;
  transform: translateY(225%) translateX(-50%);
  height: 14px;
  line-height: 13px;
}

.button-57:after {
  content: "";
  position: absolute;
  bottom: -50%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #d24545 ;
  transform-origin: bottom center;
  transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
  transform: skewY(9.3deg) scaleY(0);
  z-index: 50;
}

.button-57:hover:after {
  transform-origin: bottom center;
  transform: skewY(9.3deg) scaleY(2);
}

.button-57:hover span:last-child {
  transform: translateX(-50%) translateY(-100%);
  opacity: 1;
  transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
}
.footerMain {
  height: 26rem;
  
  display: flex;
}

.footerUpperMain{
  background-color: #e6baa3;
}

.footerImg {
  width: 50%;
  padding-top: 1rem;
  align-items: center;
  text-align: center;
}

.footerImg img {
  border-radius: 50px;
}

.footerImg p {
  font-size: 1.2rem;
  margin-left: 2rem;
  width: 30rem;
  font-weight: 600;
}

.footerContact {
  width: 100%;
  margin-left: 10%;
}

.footerContact h1 {
  margin-top: 5%;
  text-align: justify;
  display: flex;
  justify-content: left;
  align-items: center;
}

.footerLinks {
  display: flex;
  justify-content: space-evenly;
  width: 8rem;
  align-items: center;
  flex-direction: column;
  height: 15rem;
}

.footerLinkMain {
  margin-left: 3rem;
  margin-top: 2.5rem;
}

.footerLinks span {
  cursor: pointer;
}
.footerLinks span:hover {
  border-bottom: 3px solid #d24545;
}

.footerMobMain {
  height: 35rem;
  background-color: #e6baa3;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.footerMobImg {
  width: 100%;
  height: 30%;
  padding-top: 1rem;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
}

.footerMobImg img {
  border-radius: 50px;
}

.footerMobContact {
  width: 100%;
}

.footerMobContact h1 {
  margin-top: 5%;
  text-align: justify;
  font-size: 1.2rems;
}

.anchor:hover{
  color:brown;
}

@font-face{
  font-family: "Betm Font";
  src: url("../font/footerFont.otf") format("opentype");
}



